import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import api from "../../../../../service/api";
import { useNavigate, useParams } from "react-router-dom";
import { addDefaultOption } from "../../../../../utils/utility";
import { FileUpload } from "../../../../../components/FileUpload";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { AsyncPaginate } from "react-select-async-paginate";
import { paymentMethodOptions } from "./PaymentMethodOptions";

const PaymentForm = ({ getPayments, invoice, getInvoice }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const amountInputRef = useRef(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initValues, setInitValues] = useState({
        date: "",
        amount: "",
        reference_number: "",
        remarks:"",
        // is_different_customer: false,
        different_customer_id: "",
        cn_id:{},
    });
    const [availableCreditNotes, setAvailableCreditNotes] = useState(invoice?.customer_credits ?? 0.00);
    const [isDifferentCustomer, setIsDifferentCustomer] = useState(false);
    const [formConfig, setFormConfig] = useState();

    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    /** validatiob schema */
    const [formSchema, setFormSchema] = useState(
        Yup.object().shape({
            amount: Yup.string().required(),
            date: Yup.string().required(),
            payment_method: Yup.string().required(),
            different_customer_id : isDifferentCustomer ? Yup.string().required() : Yup.string(),
        })
    )
    // const schema = Yup.object().shape({
    //     amount: Yup.string().required(),
    //     date: Yup.string().required(),
    //     // reference_number: Yup.string().required(),
    //     payment_method: Yup.string().required(),
    //     different_customer_id : isDifferentCustomer ? Yup.string().required() : Yup.string(),
    // });

    const [files, setFiles] = useState([]);
    
    const [paymentOption, setPaymentOption] = useState('direct_payment');
    const [isDPVisible, setIsDPVisible] = useState(true);
    const [isOPVisible, setIsOPVisible] = useState(false);
    const [hasMoreCompany, setHasMoreCompany] = useState(true);
    const [customerCredits, setCustomerCredits] = useState([]);
    const [isGettingCustomerCredits, setIsGettingCustomerCredits] = useState(false);

    const handleFormChangeDetails = (event) => {
        setInitValues({
            ...initValues,
            remarks: event.target.value,
        });
    };

    const handleAmountChange = (event) => {
        const { name, value, max } = event.target;
        let payment_amount = parseFloat(value);
        let max_value = parseFloat(max).toFixed(2)
        if(paymentOption != 'direct_payment') {
            if(max_value < payment_amount) {
                // payment_amount = event.target.value = '';
                setError(`The amount should not greater than Customer Credits: $${max_value}.`);
            }
        }

        setInitValues({
            ...initValues,
            amount: payment_amount,
        });
    }

    // const totalPaid = invoice?.total_amount - invoice?.remaining_amount;    

    /** Create payment */
    const create = async (values) => {
        try {
            
            const formData = new FormData();
            formData.append("payment_option", paymentOption);

            if (files?.length === 0) {
                setNotif({
                    notifMsg: "Please upload supporting documents.",
                    open: true,
                    severity: "danger",
                });
                // alert("Please upload supporting documents.");
                setLoading(false);
                return false;
            }
            
            formData.append("amount", values.amount);
            formData.append("date", values.date);
            formData.append("payment_method", values.payment_method);
            formData.append("reference_number", values.reference_number);
            // formData.append("is_different_customer", values.is_different_customer);
            formData.append("different_customer_id", values.different_customer_id);
            formData.append("remarks", values.remarks);
            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });
            // setLoading(true);
            setIsSubmitting(true);
            await api.post(`tms/invoices/${id}/payments`, formData);
            await getPayments(id); // get the payment history
            await getInvoice(id); // get invoice details
            setIsSubmitting(false);
            // setLoading(false);
        } catch (error) {
            const message = error?.response?.data?.message || error?.message || "Something went wrong";
            const delay = ms => new Promise(res => setTimeout(res, ms));
            setNotif({
                notifMsg: message,
                open: true,
                severity: "danger",
            });
            await delay(2000)
            getInvoice(id);
            getPayments(id);
            setError(error?.message);
            // setLoading(false);
            setIsSubmitting(false);
        }
    };

    const paymentOptions = (event) => {
        const payment = event.target.value;
        console.log("THIS IS PAYMENT: ", payment);
        setPaymentOption(payment);

        // Get the DOM node associated with the ref
        const targetNode = event.target;

        // Find the closest element with a specific ID
        const payment_method = targetNode.closest('form').querySelector('.payment_method');
        
        if(payment == 'direct_payment') {
            setIsDPVisible(true);
            setIsOPVisible(false);

            // Setting the isDifferentCustomer to its default value of false
            // along with other variables connected to it back to their respective
            // default values.
            setInitValues({
                ...initValues,
                // is_different_customer: false,
                different_customer_id: ''
            });
            handleGetCustomerCredits(invoice?.company_id);
            setIsDifferentCustomer(false);
        } else {
            setIsDPVisible(false);
            setIsOPVisible(true);
            payment_method.value = 'OFFSET';
            payment_method.selected = 'selected';
            setInitValues({
                ...initValues,
                payment_method: 'OFFSET',
            });

            checkAmountInputValue();
        }

        // We set this regardless of which is visible because when
        // we go back to Direct payment we reset the different customer back to null
        setAvailableCreditNotes(invoice?.customer_credits ?? 0);
    };

    const handleLoadMoreCompany = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }
        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });
        const { options: newOptions, hasMore: newHasMore } = response;
        setHasMoreCompany(newHasMore);
        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const checkAmountInputValue = () => {
        if (invoice?.customer_credits < amountInputRef.current.value){
            amountInputRef.current.value = (invoice?.customer_credits > 0) ? invoice?.customer_credits : null;
        }
    }

    const handleGetCustomerCredits = async (companyId) => {
        if (!companyId){
            return;
        }

        try{
            setIsGettingCustomerCredits(true);
            const customerCreditsResponse = await api.get(`tms/company/customer-credits/${companyId}`);
            console.log(customerCreditsResponse.data.customer_credits);
            setCustomerCredits(customerCreditsResponse.data.customer_credits);
        }catch(error){
            setIsGettingCustomerCredits(false);
            console.error(error);
        }
        setIsGettingCustomerCredits(false);
    }

    useEffect(() => {
        let isMounted = true;
      
        const fetchData = async () => {
          try {
            const configs = await api.get(`tms/invoices/get-config`);
      
            // Check if the component is still mounted before updating state
            // Did this because I'm encountering error:
            // Can't perform a React state update on an unmounted component. 
            if (isMounted) {
              handleGetCustomerCredits(invoice?.company_id);
              setFormConfig(configs.form.fields);
            }
          } catch (error) {
            console.error(error);
          }
        };
      
        fetchData();

        // Cleanup function to set isMounted to false when the component is unmounted
        return () => {
          isMounted = false;
        };
      
      }, [invoice, getInvoice]);
      

    useEffect(() => {
        // Updating the schema to set different_customer_id as required
        setFormSchema(Yup.object().shape({
            amount: Yup.string().required(),
            date: Yup.string().required(),
            payment_method: Yup.string().required(),
            different_customer_id: isDifferentCustomer ? Yup.string().required() : Yup.string(),
          }));

        // setInitValues({
        //     ...initValues,
        //     is_different_customer: isDifferentCustomer,
        // });

        if (isDifferentCustomer && isOPVisible){
            // console.log("DIFFERENT CUSTOMER BUT OP IS VISIBLE ----");
            setAvailableCreditNotes(0.00);
            setCustomerCredits([]);
        }
        else if (!isDifferentCustomer && isOPVisible){
            // console.log("NOT DIFFERENT CUSTOMER BUT OP IS VISIBLE");
            setInitValues({
                ...initValues,
                different_customer_id: '',
            });

            setAvailableCreditNotes(invoice?.customer_credits ?? 0);
            handleGetCustomerCredits(invoice?.company_id);
            checkAmountInputValue();
        }
    }, [isDifferentCustomer])


    // Handling when a different company/customer is selected
    useEffect(async () => {
        // console.log("NEW CUSTOMER ID: ", initValues.different_customer_id);
        if (initValues.different_customer_id != '' && initValues.different_customer_id != null && paymentOption == 'offset_payment'){
            //QUERY the available customer credits for the selected company.
            try {
                let response = await api.get(`tms/company/available-customer-credits/${initValues.different_customer_id}`);
                handleGetCustomerCredits(initValues.different_customer_id);
                // console.log("CUSTOMER CREDITS: ", response);
                setAvailableCreditNotes(response?.data?.available_credits ?? 0 );
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with retrieving customer credits",
                    open: true,
                    severity: "danger",
                });
            }
        }
        else{
            if (isDifferentCustomer){
                setAvailableCreditNotes(0.0);
                setCustomerCredits([]);
            }
        }
    }, [initValues.different_customer_id])

    return (
        <>
            {invoice?.remaining_amount <= 0 && invoice?.status?.toLowerCase() !== 'cancelled' &&(
                <div>
                    <Alert className="my-3" variant="success">
                        <div className="alert-message">
                            Payment already made{" "}
                        </div>
                    </Alert>
                </div>
            )}
            {invoice?.status?.toLowerCase() === 'cancelled' &&(
                <div>
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            Invoice cancelled{" "}
                        </div>
                    </Alert>
                </div>
            )}
            {invoice?.remaining_amount > 0 && (
                <Formik
                    enableReinitialize
                    initialValues={initValues}
                    validationSchema={formSchema}
                    onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                    ) => {
                        try {
                            await create(values);
                            setSubmitting(true);
                        } catch (error) {
                            const message =
                                error?.message || "Something went wrong";
                            setNotif({
                                notifMsg: message,
                                open: true,
                                severity: "danger",
                            });
                        }
                    }}
                >
                    {({ errors, handleSubmit, values, setFieldValue }) =>
                        !loading && (
                            <>
                                <Form noValidate onSubmit={handleSubmit}>
                                    {error && (
                                        <Alert
                                            className="my-3"
                                            variant="danger"
                                        >
                                            <div className="alert-message">
                                                {error}
                                            </div>
                                        </Alert>
                                    )}

                                    <Row className="mb-4">
                                        <Col className="md-2">
                                            <Form.Check 
                                                type="radio"
                                                label="Direct payment"
                                                name="payment"
                                                id="direct_payment"
                                                value="direct_payment"
                                                checked={paymentOption === 'direct_payment'}
                                                onChange={paymentOptions}
                                            />
                                        </Col>
                                        <Col className="md-2">
                                            <Form.Check 
                                                type="radio"
                                                label="Offset payment"
                                                name="payment"
                                                id="offset_payment"
                                                value="offset_payment"
                                                checked={paymentOption === 'offset_payment'}
                                                onChange={paymentOptions}
                                            />
                                        </Col>
                                    </Row>
                                    
                                    <Row className="direct_payment">
                                        {paymentOption === 'offset_payment' && (
                                        <>
                                            <Row className="mb-3"> 
                                                <Col md={4}>
                                                <Form.Check 
                                                    type="checkbox"
                                                    label="Change company"
                                                    name="different_customer"
                                                    id="different_customer"
                                                    checked={isDifferentCustomer}
                                                    onChange={() => setIsDifferentCustomer(!isDifferentCustomer)}
                                                />
                                                
                                                </Col>
                                                {isDifferentCustomer && (
                                                <Col md={8}>
                                                    <Form.Group >
                                                        <AsyncPaginate
                                                            isClearable
                                                            name='company'
                                                            loadOptions={handleLoadMoreCompany}
                                                            hasMore={hasMoreCompany}
                                                            additional={{
                                                                page: 1,
                                                            }}
                                                            onChange={(target) => {
                                                                
                                                                const value = target ? target?.value : '';
                                                                setInitValues({
                                                                    ...initValues,
                                                                    different_customer_id: value,
                                                                });
                                                            }}
                                                            className={!!errors?.different_customer_id ? "required-async-paginate" : ""}
                                                            debounceTimeout={1000}
                                                        />
                                                        {!!errors?.different_customer_id && (
                                                            <div style={{color: "red"}}>
                                                                Customer is required when choosing different company
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                )}
                                                {!isDifferentCustomer && (
                                                <Col md={8}>
                                                    <p><b>{invoice?.company?.name}</b></p>
                                                </Col>
                                                )}
                                            </Row>
                                        </>
                                        )}

                                        {paymentOption === 'offset_payment' && (!isGettingCustomerCredits) && (customerCredits.length > 0) && (
                                            <Row className="pb-3">
                                                <Table responsive striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-nowrap">S/n</th>
                                                            <th className="text-nowrap">CN Reference No.</th>
                                                            <th className="text-nowrap">Amount</th>
                                                            <th className="text-nowrap">Type</th>
                                                            <th className="text-nowrap">Remarks</th>
                                                            <th className="text-nowrap">Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        customerCredits?.map(
                                                            (data, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td className="text-nowrap">{key + 1}</td>
                                                                        <td className="text-nowrap">{data?.cn_number ?? 'N/A'}</td>
                                                                        <td className="text-nowrap">{data?.amount}</td>
                                                                        <td className="text-nowrap">{data?.type?.toUpperCase() === 'CREDIT' ? data?.type : "Used" }</td>
                                                                        <td className="text-nowrap">{data?.remarks}</td>
                                                                        <td className="text-nowrap">{new Date(data?.created_at).toISOString().split('T')[0]}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        )}

                                        {paymentOption === 'offset_payment' && (!isGettingCustomerCredits) && (customerCredits.length <= 0) && (
                                            <Row className="pb-3">
                                                <p style={{color:'red'}}>No available customer credits.</p>
                                            </Row>
                                        )}

                                        {paymentOption === 'offset_payment' && (isGettingCustomerCredits) && (
                                            <Row className="pb-3">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </Row>
                                        )}

                                        
                                        {paymentOption === 'offset_payment' && (customerCredits.length > 0) &&(
                                        <>
                                            <Row>
                                                <Col md={4}>Available credits: </Col>
                                                <Col md={8} className="mb-3">
                                                    {Number(availableCreditNotes).toFixed(2)}
                                                </Col>
                                            </Row>
                                        </>
                                        )}
                                        
                                        <Row>
                                            <Col md={4}>Amount</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="number"
                                                        name="amount"
                                                        placeholder={isOPVisible ? Number(availableCreditNotes ?? 0).toFixed(2) : Number(invoice?.remaining_amount).toFixed(2)}
                                                        isInvalid={
                                                            errors?.amount
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => handleAmountChange(event)}
                                                        max={isOPVisible ? (availableCreditNotes ?? 0) : invoice?.remaining_amount}
                                                        min={0}
                                                        ref={amountInputRef}
                                                    />
                                                    {!!errors?.amount && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Amount is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Payment date</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="date"
                                                        name="date"
                                                        isInvalid={
                                                            errors?.date
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            setInitValues({
                                                                ...initValues,
                                                                date: event
                                                                    .target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                    {!!errors?.date && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Payment date is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Payment method</Col>
                                            <Col md={8}>
                                                <Select
                                                    className="is-invalid react-select-container mb-3 payment_method"
                                                    classNamePrefix="react-select"
                                                    value={isOPVisible ? { label: 'Offset', value: 'OFFSET' } : values.department}
                                                    options={addDefaultOption(isDPVisible ? paymentMethodOptions.filter(option => option.value !== "OFFSET") : paymentMethodOptions)}
                                                    name="department"
                                                    isDisabled={isOPVisible}
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "payment_method",
                                                            isOPVisible ? 'OFFSET' : event.value
                                                        );
                                                        setInitValues({
                                                            ...initValues,
                                                            payment_method:
                                                                isOPVisible ? 'OFFSET' : event.value,
                                                        });
                                                    }}
                                                />
                                                {!!errors?.payment_method && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Payment method is
                                                        required
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Reference number</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="reference_number"
                                                        isInvalid={
                                                            errors?.reference_number
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            setInitValues({
                                                                ...initValues,
                                                                reference_number:
                                                                    event.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                    {!!errors?.reference_number && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Reference number is
                                                            required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Remarks</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <AutoGrowTextarea
                                                        name="remarks"
                                                        handleFormChangeDetails={
                                                            handleFormChangeDetails
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Upload</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <FileUpload
                                                        className="fileupload-bg"
                                                        files={files}
                                                        setFiles={setFiles}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md={5}>
                                            {isSubmitting && (
                                                <>
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                </>
                                            )}
                                            {!isSubmitting && (
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                className="col-md-12"
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                            )}
                                        </Col>
                                        <Col md={5}>
                                            <Button
                                                onClick={() =>
                                                    navigate("/tms/invoices")
                                                }
                                                variant="danger"
                                                className="col-md-12"
                                                size="lg"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        )
                    }
                </Formik>
            )}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
export default PaymentForm;
