import React, { useEffect, useRef, useState } from "react";
import { useTable, usePagination, useSortBy, useRowSelect, useBlockLayout } from "react-table";
import { Table, Row, Col, Alert, Pagination, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSticky } from "react-table-sticky";
import ListButton from "./ListButton";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { DeleteModal } from "../../../../../components/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faFilePdf,
} from "@fortawesome/free-regular-svg-icons";
import download from "downloadjs";
import {
    listTableNumberFormat,
    listItemCodes,
    scrollButtonFunction,
} from "../../../../../utils/utility";
import styled from "styled-components";
import DoubleScrollbar from "react-double-scrollbar";
import { debounce } from 'lodash';
import { handleCalculateTop, handleStickyHeaderObserver, handleSyncHorizontalScroll, stickyHeaderResizeListener, syncHeaderStyles } from "../../../../../utils/stickyHeaderHelper";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const Lists = ({ columns, rawData, invoiceApi, type, navigationStickyComponent, filterStickyComponent }) => {
    // const navigate = useNavigate();
    // const params = useParams();
    const [error, setError] = useState();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const generatePdfRowComponent = useRef(null)
    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination,
        useRowSelect,
        useBlockLayout,
        useSticky,
        (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        sticky: "left",
                        width: "50",
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
        }
    );
    const gotoPage = (page) => {
        invoiceApi({
            page,
        });
    };

    const generatePdf = async () => {
   
        const invoices = selectedFlatRows.map((d) => {
            return {
                invoice_id: d.original.id,
            };
        });
        console.log(invoices)

        if (invoices.length <= 0) {
            alert('Please select invoice');
        }
        
        try {
            setIsGeneratingPdf(true);
            const filename = `sales-invoices-${new Date()
                .toISOString()
                .slice(0, 10)}`;

            const response = await api.postZip("tms/export-pdf-invoices",invoices);

            if (response?.type === 'application/zip') {
                download(response, filename, "application/zip");
            }else if (response?.type === 'application/json') {
                setNotif({
                    id: null,
                    notifMsg: "Please check your email in few minutes.",
                    open: true,
                    severity: "success",
                });
            }
            else {
                setError("No data to be exported");
            }
        } catch (error) {
            // setLoading(false);
            setError(error.message);
            setIsGeneratingPdf(false);
        }
        // setLoading(false);
        setIsGeneratingPdf(false);
    };

    const deleteCompany = async (id) => {
        try {
            await api.delete(`tms/sales-quotations/${id}`, {});
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            invoiceApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    // const openDeleteModal = (id) => {
    //     setModalInfo({
    //         id: id,
    //         notifMsg: "Are you sure you want to delete this item?",
    //         open: true,
    //         severity: "danger",
    //     });
    // };

    // const Styles = styled.div`
    //     .table {
    //         .th,
    //         .td {
    //             background-color: #fff;
    //             overflow: hidden;
    //             border-top: none;
    //         }

    //         &.sticky {
    //             overflow: scroll;
    //             .header,
    //             .footer {
    //                 position: sticky;
    //                 z-index: 1;
    //                 width: fit-content;
    //             }
    //         }
    //     }
        
    //     .table > :not(:first-child) {
    //         border-top: none;
    //     }
    // `;

    const mainTableRef = useRef(null);
    const stickyTableRef = useRef(null);

    const tableHeaderRef = useRef(null);
    const [headerStyles, setHeaderStyles] = useState([]);
    const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false); // Flag to track if sticky class is applied
    const [styleTop, setStyleTop] = useState(0);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            handleCalculateTop(filterStickyComponent, setStyleTop, type === 'report');
        });
    }, [])

    useEffect(() => {
        stickyHeaderResizeListener(setHeaderStyles, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop, ['report', 'operation'].includes(type));
    }, [data]);

    useEffect(() => {
        let currentRootMargin = isStickyHeaderVisible ? "-60px 0px 0px 0px" : "-10px 0px 0px 0px";
        if (type === 'report') {
            currentRootMargin = isStickyHeaderVisible ? "-425px 0px 0px 0px" : "-385px 0px 0px 0px";
        }
        if(type === 'operation'){
            currentRootMargin = isStickyHeaderVisible ? "-253px 0px 0px 0px" : "-90px  0px 0px 0px";
        }

        handleStickyHeaderObserver(isStickyHeaderVisible, tableHeaderRef,  currentRootMargin, setIsStickyHeaderVisible, setHeaderStyles, stickyTableRef, mainTableRef);
    }, [isStickyHeaderVisible]);

    return (
        <>  
        <Row className={`${['report', 'operation'].includes(type) ? 'fixed-header-filter-invoice' : 'fixed-header-w-navbar'} pt-3`} style={{ top: styleTop }}>
            <Col md="4" className="d-flex justify-content-start align-items-center">
                <div className="scroll-buttons pb-2 px-0">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Seek Left</Tooltip>}
                    >
                        <Button
                            onClick={scrollButtonFunction('.table-target-for-scroll', false, "auto") }
                            variant="secondary"
                            size="md"
                        >
                            <FontAwesomeIcon
                                icon={faArrowAltCircleLeft}
                            />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Seek Right</Tooltip>}
                    >
                        <Button
                            variant="secondary"
                            size="md"
                            onClick={scrollButtonFunction('.table-target-for-scroll', true, "auto") }
                            className="mx-3 info"
                        >
                            <FontAwesomeIcon
                                icon={faArrowAltCircleRight}
                            />
                        </Button>
                    </OverlayTrigger>
                </div>
            </Col>

            {totalPage > 1 && (
                <Col md={8}>
                    <Row ref={navigationStickyComponent}>
                        {/* <Col md="6">
                            <span className="mx-2">
                                Page {""}
                                <strong>
                                    {currentPage} of {totalPage}
                                </strong>
                            </span>
                        </Col> */}
                        <Col md="12">
                            <Pagination className="float-end ">
                                <Pagination.First
                                    onClick={() => gotoPage(firstPage)}
                                    disabled={!canPreviousPage}
                                />
                                <Pagination.Prev
                                    onClick={(i) =>
                                        gotoPage(currentPage - 1)
                                    }
                                    disabled={!canPreviousPage}
                                />
                                <Pagination.Next
                                    onClick={() =>
                                        gotoPage(currentPage + 1)
                                    }
                                    disabled={!canNextPage}
                                />
                                <Pagination.Last
                                    onClick={() => gotoPage(lastPage)}
                                    disabled={!canNextPage}
                                />
                            </Pagination>
                            <span className="mx-2 float-end">
                                Page {""}
                                <strong>
                                    {currentPage} of {totalPage}
                                </strong>
                            </span>
                        </Col>
                    </Row>
                </Col>
                        
            )}

            {isStickyHeaderVisible && (['report', 'operation'].includes(type)) && (
                <div className="mx-0 sticky-header-container">
                    <div className="double-scrollbar-table-style">
                    <div className="table-responsive">
                        <DoubleScrollbar>
                            <br></br>
                            <Table  striped bordered className="table-target-for-scroll" ref={stickyTableRef} {...getTableProps()} style={{ overflowX: "hidden" }}>
                                <thead>
                                    {headerStyles.map((style, index) => (
                                        <tr style={style} key={index}>
                                            {style.headerChildren.map((tdStyle, tdIndex) => (
                                                <th key={tdIndex} style={tdStyle}>
                                                    {headerGroups[0]?.headers[tdIndex]?.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                            </Table>
                        </DoubleScrollbar>
                    </div>
                    </div>
                </div>
            )}
        </Row>
            
            {selectedFlatRows.map((d) => d.original.id).length >
                0 && (!isGeneratingPdf) && (
                    <Row className={`${type === 'report' ? 'fixed-header-generate-pdf-invoice' : ''} py-3`} ref={generatePdfRowComponent}>
                        <Col md={1}>
                            <Form.Group className="mb-3  mt-1 mx-1">
                                <Button
                                    className="mt-1 mx-1"
                                    variant="info"
                                    type="submit"
                                    size="lg"
                                    onClick={generatePdf}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            </Form.Group>
                        </Col>
                            <br></br>
                    </Row>
                
            )}

            {selectedFlatRows.map((d) => d.original.id).length >
                0 && (isGeneratingPdf) && (
                    <Row className={`${type === 'report' ? 'fixed-header-generate-pdf-invoice' : ''} py-3`}>
                        <Col md={1} className="d-flex justify-content-center">
                            {/* Spinner for while generating batch pdf */}
                            <div className="spinner-border" role="status" ref={generatePdfRowComponent}>
                                <span class="sr-only">Loading...</span>
                            </div>
                        </Col>
                    </Row>
            )} 

            {data.length > 0 && (
                <Row>               
                    <div className="double-scrollbar-table-style">
                        <div className="table-responsive">
                            <DoubleScrollbar>
                                <br></br>
                                <Table striped bordered className="table-target-for-scroll" ref={mainTableRef} {...getTableProps()} >
                                    <thead className="fixed-table-header" ref={tableHeaderRef}>
                                        {headerGroups?.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                                                {headerGroup.headers?.map((column) => (
                                                    <th
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        className="th"
                                                    >
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="body">
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} className="tr">
                                                    {row.cells.map((cell, index, row) => {
                                                        if (index === 1) {
                                                            return (
                                                                <td key={index} {...cell.getCellProps()} className="td">
                                                                    {currentPage > 1
                                                                        ? (currentPage -
                                                                            1) *
                                                                            pageSize +
                                                                        i +
                                                                        1
                                                                        : i + 1}
                                                                </td>
                                                            );
                                                        }

                                                        if (!index == 0 ){
                                                            if (
                                                                cell.column.Header.toLowerCase() == "invoice amount" || 
                                                                cell.column.Header.toLowerCase() == "remaining amount") {
                                                                    return(listTableNumberFormat(cell, cell.value));
                                                            }
                                                            if (cell.column.Header.toLowerCase() == "total paid") {
                                                                return(listTableNumberFormat(cell, cell.value));
                                                            }
                                                        }
                                                        
                                                        if (index + 1 === 3) {
                                                            return (
                                                                <td key={index} {...cell.getCellProps()} className="td" >
                                                                    <ListButton
                                                                        id={
                                                                            cell.row
                                                                                .original.id ?? ""
                                                                        }
                                                                        mainInvoiceId={
                                                                            cell.row
                                                                                .original.main_invoice_id ?? ""
                                                                        }
                                                                        currentPage={
                                                                            currentPage
                                                                        }
                                                                        invoice_number={
                                                                            cell.row
                                                                                .original
                                                                                .number ?? ""
                                                                        }
                                                                        type={type}
                                                                        isExpired={
                                                                            cell.row
                                                                                .original
                                                                                .is_date_expired ?? ""
                                                                        }
                                                                        isDownloadable={
                                                                            cell.row
                                                                                .original
                                                                                .invoice_courses[0] ?? []
                                                                        }
                                                                        isSync_enrolment={
                                                                            cell.row
                                                                                .original
                                                                                .sync_enrolment ?? ""
                                                                        }
                                                                        status_key={
                                                                            cell.row
                                                                                .original
                                                                                .status_key ?? ""
                                                                        }
                                                                        setNotif={setNotif}
                                                                        rawData={
                                                                        cell.row
                                                                            .original
                                                                    }
                                                                    />
                                                                </td>
                                                            );
                                                        }
                                                        return (
                                                            <td {...cell.getCellProps()} className="td">
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </DoubleScrollbar>
                        </div>
                    </div>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No invoices</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
