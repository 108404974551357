import React, { useEffect, useState, useContext } from "react";
import { Alert, Badge, Button, Col, Row, Modal, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { ArrowLeft, CheckCircle, Eye, Settings } from "react-feather";
import { useNavigate } from "react-router-dom";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import api from "../../../../../service/api";
import { AuthContext } from "../../../../../contexts/JWTContext";
import { useSelector } from "react-redux";

const Courses = ({ invoice, getInvoiceCourseStudent, getInvoice }) => {
    const navigate = useNavigate();
    const [error, setError] = useState();

    const [loading, setLoading] = useState(false);
    // const [allowedRegisterStudents, setAllowedRegister] = useState(false);
    const [allowedFinaliseStudents, setAllowedFinalize] = useState(false);

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [show, setShow] = useState(false);
    const [modalType, setModalType] =useState('APPROVAL');
    const [modalLoading, setModalLoading] = useState(false);
    const [syncingStudents, setSyncingStudents] = useState(false);
    const [approvalInvoiceId, setApprovalInvoiceId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const authContext = useContext(AuthContext);

    const [modalData, setModalData] = useState({
        remarks: "",
        user_id: authContext?.user?.id ?? null
    });

    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });

    const permission = data?.permissions;

    /** Modal popup */
    const setModalPopUp = (status, invoiceId = "", type="APPROVAL") => {
        setModalLoading(true);
        setModalType(type);
        setShow(status);
        setApprovalInvoiceId(invoiceId);
        setModalLoading(false);
        setModalData({
            remarks: "",
            user_id: authContext?.user?.id ?? null
        })
    };

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const requestForStudentToRegister = async () => {
        try {
            setIsSubmitting(true);

            const response = await api.post(
                `tms/invoices/request-approval-from-director/${approvalInvoiceId}`,
                modalData
            );
            
            if (response?.success) {
                setNotif({
                    notifMsg: "Sending request email",
                    open: true,
                    severity: "success",
                });
                await delay(2000);
                await getInvoice(approvalInvoiceId ?? invoice?.id);
            }
        } catch (error) {
            setIsSubmitting(false);
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
    }

    const allowStudentToRegister = async () => {
        try {
            await api.post(
                `tms/invoices/allowed-to-register-students/${approvalInvoiceId}`,
                modalData
            );
            setNotif({
                notifMsg: "Successfully  allowed to register students ",
                open: true,
                severity: "success",
            });
            await delay(2000);
            await getInvoice(approvalInvoiceId ?? invoice?.id);
        } catch (error) {
            setError(error?.response?.data?.message);
            
        }
    };

    const syncStudentToEnrollment = async (id) => {
        try {
            setSyncingStudents(true);
            await api.post(`tms/invoices/enroll-students/${id}`, {});
            setNotif({
                notifMsg: "Successfully synced to enrollment ",
                open: true,
                severity: "success",
            });
            await delay(2000);
            await getInvoice(invoice?.id ?? approvalInvoiceId);
        } catch (error) {
            setError(error?.response?.data?.message);
        }
        setSyncingStudents(false);
    };

    useEffect(() => {
        setLoading(true);
        // const allowedStudentList = invoice?.invoice_courses?.filter((item) => {
        //     return item.total_student === item.allowed_students;
        // });
        // const allowedFinaliseStudents = invoice?.invoice_courses?.filter((item) => {
        //     return item.total_student === item.allowed_students;
        // });
        // console.log(invoice?.invoice_courses);
        setAllowedFinalize(
            invoice?.status_key == "PAID" || invoice?.status_key == "OVERPAID" ||
                invoice?.sales_director_approval !== null
        );
        // setAllowedRegister(
        //     allowedStudentList?.length === invoice?.invoice_courses?.length
        // );
        setLoading(false);
    }, [invoice]);

    return (
        <>
            {error && (
                <Alert className="my-3" variant="danger">
                    <div className="alert-message">{error}</div>
                </Alert>
            )}
            {loading && <div>Loading...</div>}
            {!loading && (
                <>
                    <Row>
                        <Col md={6}>
                            <h3 className="mb-0 pb-0">
                                {invoice?.company?.name}
                            </h3>
                            <h5 className="mb-3 ">{invoice?.company?.uen}</h5>
                        </Col>
                        <Col md={6}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>Return to List</Tooltip>}
                            >
                                <Button
                                    variant="info"
                                    className="float-end  ms-3  "
                                    onClick={() => {
                                        navigate("/tms/invoices");
                                    }}
                                >
                                    <ArrowLeft size={15}></ArrowLeft>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>View Invoice</Tooltip>}
                            >
                                <Button
                                    variant="info"
                                    className="float-end  ms-3  "
                                    onClick={() => {
                                        navigate(
                                            `/tms/invoices/view/${invoice?.id}`
                                        );
                                    }}
                                >
                                <Eye size={15}></Eye>
                            </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <h5 className="mb-0">
                                <b>Invoice number: {invoice?.number}</b>
                                <br />
                                <b>Invoice amount: ${invoice?.total_amount}</b>
                                <br />
                                <b>
                                    Status: <Badge>{invoice?.status}</Badge>
                                </b>
                            </h5>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex justify-content-end align-items-center">
                                {!invoice?.sync_enrolment && !syncingStudents &&
                                    allowedFinaliseStudents &&
                                    invoice?.invoice_type !== "AD-HOC" &&
                                    invoice?.main_invoice_id === null && 
                                    permission?.includes("FINALISE-INVOICE") &&(
                                        <Button
                                            variant="success"
                                            onClick={() => syncStudentToEnrollment(invoice?.id)}
                                            className="ms-3"
                                            disabled={syncingStudents}
                                        >
                                            <CheckCircle className="feather" size={15}></CheckCircle>{" "}
                                            Finalise
                                        </Button>
                                )}

                                {!invoice?.sync_enrolment && syncingStudents && (
                                    <>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    </>
                                )}

                                {(invoice?.status_key === "PENDING" ||
                                    invoice?.status_key === "PARTIAL-PAID") &&
                                    invoice?.sales_director_approval === null &&
                                    invoice?.invoice_type !== "AD-HOC" &&
                                    invoice?.main_invoice_id === null &&
                                    permission?.includes("SALES-DIRECTOR") && (
                                        <Button
                                            variant="success"
                                            className="ms-3"
                                            onClick={() => {
                                                setModalPopUp(true, invoice?.id);
                                            }}
                                        >
                                            Approval
                                        </Button>
                                    )}

                                {(invoice?.status_key?.toUpperCase() === "PENDING" ||
                                    invoice?.status_key?.toUpperCase() === "PARTIAL-PAID") &&
                                    invoice?.sales_director_approval === null &&
                                    invoice?.invoice_type !== "AD-HOC" &&
                                    invoice?.requested_director_approval === false &&
                                    invoice?.main_invoice_id === null &&
                                    !isSubmitting &&
                                    permission?.includes("REQUEST-APPROVAL")  && (
                                        <Button
                                            variant="warning"
                                            className="ms-3"
                                            onClick={() => {
                                                setModalPopUp(true, invoice?.id, "REQUEST");
                                            }}
                                        >
                                            Request approval
                                        </Button>
                                    )
                                }

                                { (invoice?.status_key?.toUpperCase() === "PENDING" ||
                                    invoice?.status_key?.toUpperCase() === "PARTIAL-PAID") &&
                                    invoice?.sales_director_approval === null &&
                                    invoice?.requested_director_approval === false &&
                                    invoice?.main_invoice_id === null &&
                                    isSubmitting &&
                                
                                    (
                                        <div className="ms-3">
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    )
                                }

                                { (invoice?.status_key === "PENDING" ||
                                    invoice?.status_key === "PARTIAL-PAID") &&
                                    invoice?.sales_director_approval === null &&
                                    invoice?.requested_director_approval === true &&
                                    invoice?.main_invoice_id === null &&
                                    !isSubmitting &&
                                    permission?.includes("REQUEST-APPROVAL") && (
                                        <div className="ms-3">
                                            <h4><Badge bg="warning">Pending approval from sales director.</Badge></h4>
                                        </div>
                                    )
                                }

                                { (invoice?.status_key === "PENDING" ||
                                    invoice?.status_key === "PARTIAL-PAID") &&
                                    invoice?.sales_director_approval === null &&
                                    invoice?.requested_director_approval === true &&
                                    invoice?.main_invoice_id === null &&
                                    isSubmitting &&
                                    permission?.includes("REQUEST-APPROVAL") &&
                                    (
                                        <div className="ms-3">
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    )
                                }

                                {/* {allowedRegisterStudents && (
                                    <>
                                        <CheckCircle
                                            className="feather ms-3"
                                            size={15}
                                        ></CheckCircle>{" "}
                                        Allowed to register students
                                    </>
                                )} */}
                            </div>
                        </Col>
                    </Row>
                    <hr size={5} className="text-secondary" />
                    {invoice?.invoice_course?.length === 0 && (
                        <div className="text-danger">No courses</div>
                    )}
                    {invoice?.invoice_courses?.length > 0 && (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td width="10%">S.no </td>
                                    <td>Course </td>
                                    <td width="10%">Total student</td>
                                    <td width="10%">Total allowed</td>
                                    <td width="10%">Registered student</td>
                                    <td width="10%">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice?.invoice_courses?.map(
                                    (courses, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {invoice?.department ===
                                                        "ATTC" && (courses?.item_type !== 'discount') && (courses?.item_type !== 'additional') && (
                                                        <>
                                                            {courses?.course?.name}
                                                            <ul>
                                                                <li>
                                                                    Trade:
                                                                    {
                                                                        courses?.trade_category
                                                                    }
                                                                </li>
                                                                <li>
                                                                    Trade category:
                                                                    {
                                                                        courses?.type_of_trade
                                                                    }
                                                                </li>
                                                                <li>
                                                                    Application:
                                                                    {
                                                                        courses?.application_option
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </>
                                                    )}

                                                    {invoice?.department ===
                                                        "ATTC" && (courses?.item_type === 'discount') && (
                                                        <>{courses?.discount_item_name ?? "Discount"}</>
                                                    )}

                                                    {courses?.adhoc_additional_item_code && (courses?.item_type !== 'discount') && (
                                                        <>
                                                            <b>Item code:</b> {courses?.adhoc_additional_item_code}
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {courses?.total_student}
                                                </td>
                                                <td>
                                                    {courses?.allowed_students}
                                                </td>
                                                <td>
                                                    {
                                                        courses?.registered_student
                                                    }
                                                </td>

                                                <td>
                                                    {courses?.item_type === 'course' && (
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id={`tooltip-top`}>Manage Students</Tooltip>}
                                                        >
                                                            <Button
                                                                variant="info"
                                                                onClick={() => {
                                                                    getInvoiceCourseStudent(
                                                                        courses?.id
                                                                    );
                                                                }}
                                                            >
                                                                <Settings size={10} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    )}
                </>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />

            <Modal
                show={show && modalType === 'APPROVAL'}
                onHide={() => {
                    setModalPopUp(false, "");
                }}
            >
                <Modal.Header>
                    <h3>Approval to allow student</h3>
                </Modal.Header>
                {modalLoading && <div>Loading...</div>}
                {!modalLoading && (
                    <>
                        <Modal.Body className="text-left m-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Remarks</Form.Label>
                                <AutoGrowTextarea
                                    type="textarea"
                                    name="remarks"
                                    handleFormChangeDetails={(event) => {
                                        setModalData({
                                            ...modalData,
                                            remarks: event.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="success"
                                onClick={() => {
                                    allowStudentToRegister();
                                    setModalPopUp(false, "");
                                }}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setModalPopUp(false, "");
                                }}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>

            <Modal
                show={show && modalType === 'REQUEST'}
                onHide={() => {
                    setModalPopUp(false, "");
                }}
            >
                <Modal.Header>
                    <h3>Request approval from sales director to allow student</h3>
                </Modal.Header>
                {modalLoading && <div>Loading...</div>}
                {!modalLoading && (
                    <>
                        <Modal.Body className="text-left m-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Remarks</Form.Label>
                                <AutoGrowTextarea
                                    type="textarea"
                                    name="remarks"
                                    handleFormChangeDetails={(event) => {
                                        setModalData({
                                            ...modalData,
                                            remarks: event.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            {!isSubmitting && (
                            <Button
                                variant="warning"
                                onClick={() => {
                                    requestForStudentToRegister();
                                    setModalPopUp(false, "");
                                }}
                            >
                                Request
                            </Button>
                            )}
                            {isSubmitting && (
                                <div class="spinner-border text-warning" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            )}
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setModalPopUp(false, "");
                                }}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
};
export default Courses;
