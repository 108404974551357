import React, { useEffect, useRef, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
// import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Alert, Pagination } from "react-bootstrap";
import { debounce } from 'lodash';
import { handleCalculateTop, handleStickyHeaderObserver, stickyHeaderResizeListener, syncHeaderStyles } from "../../../../utils/stickyHeaderHelper";

const Lists = ({ columns, rawData, paymentApi, filterStickyComponent, navigationStickyComponent }) => {
    // const [modalInfo, setModalInfo] = useState({
    //     id: null,
    //     notifMsg: "",
    //     open: false,
    //     severity: "danger",
    // });

    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        paymentApi({
            page,
        });
    };

    const mainTableRef = useRef(null);
    const stickyTableRef = useRef(null);

    const tableHeaderRef = useRef(null);
    const [headerStyles, setHeaderStyles] = useState([]);
    const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false); // Flag to track if sticky class is applied
    const [styleTop, setStyleTop] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            handleCalculateTop(filterStickyComponent, setStyleTop);
        });
    }, [])

    useEffect(() => {
        stickyHeaderResizeListener(setHeaderStyles, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop);
    }, [data]);

    useEffect(() => {
        let currentRootMargin = isStickyHeaderVisible ? "-325px 0px 0px 0px" : "-250px 0px 0px 0px";
        handleStickyHeaderObserver(isStickyHeaderVisible, tableHeaderRef,  currentRootMargin, setIsStickyHeaderVisible, setHeaderStyles, stickyTableRef, mainTableRef);
    }, [isStickyHeaderVisible]);

    return (
        <>
            {data.length > 0 && (
                <Row>
                    <div className="fixed-header-navigation-changes" style={{ top: styleTop }}>
                    {totalPage > 1 && (
                        <Row  ref={navigationStickyComponent}>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    {isStickyHeaderVisible && (
                        <div className="mx-2">
                            {/* Sticky Header Container */}
                            <Table responsive striped bordered {...getTableProps()}>
                                <thead>
                                    {headerStyles?.map((style, index) => (
                                        <tr style={style} key={index}>
                                            {style.headerChildren?.map((tdStyle, tdIndex) => (
                                                <th key={tdIndex} style={tdStyle}>
                                                    {headerGroups[0]?.headers[tdIndex]?.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                            </Table>
                        </div>
                    )}
                    </div>
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead className="fixed-table-header" ref={tableHeaderRef}>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (index === 0) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            }
                                            // if (index + 1 === row.length) {
                                            //     return <td key={index}>-</td>;
                                            // }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No changes data </div>
                </Alert>
            )}
        </>
    );
};

export { Lists };
