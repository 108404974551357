import api from "../../../service/api";
import * as Yup from "yup";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import AddEditStudentDetails from "./AddEditStudentDetails";
import AddEditCourseDetails from "./AddEditCourseDetails";
import AddEditCompanyDetails from "./AddEditCompanyDetails";
import AddEditPaymentDetails from "./AddEditPaymentDetails";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";

const FormRow = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [editEnrolment, setEditEnrolment] = useState();
    const [hasInvoice, setHasInvoice] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
    });

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [initValues, setInitialValues] = useState({
        type: "attc",
        registration_type: "",
        company_id: "",
        sponsor_company_id: "",
        self_registration: "",
        trade_category: "",
        type_of_trade: "",
        application_option: "",
        application_option_original: "",
        course_id: "",
        course_fee: "",
        additional_fee: "",
        discount_fee: "",
        additional_training: "",
        payment_mode: "",
        reference_no: "",
        reference_date: "",
        invoice_no: "",
        remarks: "",
        students: [
            {
                name: "",
                email: "",
                passport: "",
                nationality: "",
                gender: "",
                date_of_birth: "",
                fin: "",
                work_permit_number: "",
                work_permit_expire_date: "",
                eligibility_period_for_an: "",
                identification_type: "",
                sector: "",
                contact_number: "",
                coretrade_multi_skill_direct: "",
            },
        ],
        
    });

    const addEnrolement = () => {
        const updateInitialValues = { ...initValues };
        const { students } = updateInitialValues;
        students.push({
            name: "",
            email: "",
            passport: "",
            nationality: "",
            gender: "",
            date_of_birth: "",
            fin: "",
            work_permit_number: "",
            work_permit_expire_date: "",
            eligibility_period_for_an: "",
            identification_type: "",
            sector: "",
            contact_number: "",
            coretrade_multi_skill_direct: "",
        });
        setInitialValues(updateInitialValues);
    };

    const deleteEnrolement = (index) => {
        const updateInitialValues = { ...initValues };
        const { students } = updateInitialValues;
        students.splice(index, 1);
        setInitialValues(updateInitialValues);
    };

    const handleFormChangeItems = (index, event) => {
        let data = [...initValues.students];
        data[index][event.target.name] = event.target.value;

        setInitialValues({
            ...initValues,
            students: data,
        });
    };

    const handleDateSelection = (index, name, value) => {
        let data = [...initValues.students];
        data[index][name] = value;
        setInitialValues({
            ...initValues,
            students: data,
        });
    };

    const handleFormSelectChangeItems = (index, event, name) => {
        let data = [...initValues.students];
        data[index][name] = event.value;
        setInitialValues({
            ...initValues,
            students: data,
        });
    };

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data.type?.toLowerCase() === "ato";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toLowerCase() === "attc";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const { atoCourses, attcCourses } = { ...configValues };

        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    /** validatiob schema */
    const schema = Yup.object().shape({
        type: Yup.string().required(),
        company_id: Yup.string().required(),
        registration_type: Yup.string().required(),
        invoice_no: Yup.string().required(),
        students: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(),
                fin: Yup.string().required(),
                identification_type: Yup.string().required(),
                sector: Yup.string().required(),
            })
        ),
    });

    /** functions */
    const create = (values) => {
        try {
            return api.post("tms/enrolments", values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const update = (values) => {
        try {
            return api.put(`tms/enrolments/${id}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    // Updating and confirming enrolment
    const confirmEnrolment = async (values) => {
        setIsSubmitting(true);
        try {
            await api.put(`tms/enrolments/confirm-enrolment/${id}`, values);
            navigate("/tms/enrolments/enrolled", {
                state: {
                    open: true,
                    notifMsg: `Successfully confirmed enrolment`,
                    severity: "success",
                },
            });
        } catch (error) {
            let errorMessage = "";
            if (error.response?.data?.errors) {
                const errors = error.response.data.errors;
                errorMessage = Object.entries(errors)
                    .map(([field, messages]) => {
                        const fieldName = field.replace(/\.\d+/g, ""); // Remove array index notation for better readability
                        return `${fieldName}: ${messages.join(", ")}`;
                    })
                    .join(" | ");
            } else if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
            }

            console.log(errorMessage);
            setNotif({
                notifMsg: errorMessage || "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }

        setIsSubmitting(false);
    }

    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                const configResponse = await api.get(
                    `tms/enrolments/config`,
                    {}
                );
                const courseResponse = await api.get(`tms/courses?all=1`, {});

                setCourseData(courseResponse?.data);
                setConfigValues({ ...configValues, ...configResponse.data });
                setLoading(false);
            } catch (error) {}
        };
        if (id && id !== "add") {
            const getEnrolment = async () => {
                try {
                    const response = await api.get(`tms/enrolments/${id}`, {});
                    setEditEnrolment(response.data);
                } catch (error) {}
            };
            getEnrolment();
        }
        getConfig();
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getEditEnrolment = async () => {
            if (editEnrolment) {
                setLoading(true);
                // Checking if enrolment is from invoice to hide irrelevant input fields.
                await setHasInvoice((editEnrolment.invoice_id != null && editEnrolment.invoice_id != ''));

                await setInitialValues({
                    type: editEnrolment.type,

                    registration_type:
                        editEnrolment?.self_registration  ? "Self" : "Company",
                    company_id: editEnrolment?.company_id,
                    sponsor_company_id: editEnrolment?.sponsor_company_id
                        ? editEnrolment?.sponsor_company_id
                        : "",
                    self_registration: editEnrolment?.self_registration,
                    trade_category: editEnrolment?.trade_category
                        ? editEnrolment?.trade_category
                        : "",
                    type_of_trade: editEnrolment?.type_of_trade
                        ? editEnrolment?.type_of_trade
                        : "",
                    application_option:
                        editEnrolment?.application_option_original
                            ? editEnrolment?.application_option_original
                            : "",
                    application_option_original:
                        editEnrolment?.application_option_original
                            ? editEnrolment?.application_option_original
                            : "",
                    course_id: editEnrolment?.course?.id,
                    course_fee: editEnrolment?.course_fee
                        ? editEnrolment?.course_fee
                        : "",
                    additional_fee: editEnrolment?.additional_fee
                        ? editEnrolment?.additional_fee
                        : "",
                    discount_fee: editEnrolment?.discount_fee
                        ? editEnrolment?.discount_fee
                        : "",
                    additional_training: editEnrolment?.additional_training,
                    payment_mode: editEnrolment?.payment_mode,
                    invoice_no: editEnrolment?.invoice_no,
                    reference_no: editEnrolment?.reference_no
                        ? editEnrolment?.reference_no
                        : "",
                    reference_date: editEnrolment?.reference_date
                        ? editEnrolment?.reference_date
                        : "",
                    remarks: editEnrolment?.remarks
                        ? editEnrolment?.remarks
                        : "",
                  
                    students: [{ 
                    id: editEnrolment?.student_id
                        ? editEnrolment?.student_id
                        : "",
                    name: editEnrolment?.name
                        ? editEnrolment?.name
                        : "",
                    email: editEnrolment?.email
                        ? editEnrolment?.email
                        : "",
                    passport: editEnrolment?.passport
                        ? editEnrolment?.passport
                        : "",
                    nationality: editEnrolment?.nationality
                        ? editEnrolment?.nationality
                        : "",
                    gender: editEnrolment?.gender
                        ? editEnrolment?.gender
                        : "",
                    date_of_birth: editEnrolment?.date_of_birth
                        ? editEnrolment?.date_of_birth
                        : "",
                    fin: editEnrolment?.fin
                        ? editEnrolment?.fin
                        : "",
                    work_permit_number: editEnrolment?.work_permit_number
                        ? editEnrolment?.work_permit_number
                        : "",
                    work_permit_expire_date: editEnrolment?.work_permit_expire_date
                        ? editEnrolment?.work_permit_expire_date
                        : "",
                    eligibility_period_for_an: editEnrolment?.eligibility_period_for_an
                        ? editEnrolment?.eligibility_period_for_an
                        : "",
                    identification_type: editEnrolment?.identification_type
                        ? editEnrolment?.identification_type
                        : "",
                    sector: editEnrolment?.sector
                        ? editEnrolment?.sector
                        : "",
                    contact_number: editEnrolment?.contact_number
                        ? editEnrolment?.contact_number
                        : "",
                    coretrade_multi_skill_direct: editEnrolment?.coretrade_multi_skill_direct
                        ? editEnrolment?.coretrade_multi_skill_direct
                        : "",
                    }],
                });

                setLoading(false);
            }
        };

        getEditEnrolment();
    }, [editEnrolment]);

    return (
        <>
            <Card>
                <Card.Body>
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                try {
                                    setIsSubmitting(true);
                                    id !== "add"
                                        ? await update(values)
                                        : await create(values);
                                    
                                    const redirectLink = "/tms/enrolments/" +
                                        (hasInvoice && editEnrolment?.status_key?.toUpperCase() === "PENDING-CONFIRMATION"
                                        ? "pending-confirmation"
                                        : "enrolled");

                                    navigate(redirectLink, {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                id === "add"
                                                    ? "added"
                                                    : "updated"
                                            } enrolment`,
                                            severity: "success",
                                        },
                                    });
                                } catch (error) {
                                    const message =
                                        error?.response?.data?.message ||
                                        "Something went wrong";
                                    setStatus({ success: false });
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                    setIsSubmitting(false);
                                    setNotif({
                                        notifMsg:
                                            "Something went wrong with the server",
                                        open: true,
                                        severity: "danger",
                                    });
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {errors.submit && (
                                        <Alert
                                            className="my-3"
                                            variant="danger"
                                        >
                                            <div className="alert-message">
                                                {errors.submit}
                                            </div>
                                        </Alert>
                                    )}

                                    <AddEditCompanyDetails
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        editEnrolment={editEnrolment}
                                        configValues={configValues}
                                        errors={errors}
                                        isHidden={hasInvoice}
                                    />

                                    <AddEditCourseDetails
                                        configValues={configValues}
                                        setFieldValue={setFieldValue}
                                        editEnrolment={editEnrolment}
                                        values={values}
                                        errors={errors}
                                        isDisabled={hasInvoice}
                                    />

                                    <AddEditStudentDetails
                                        values={values}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        handleDateSelection={
                                            handleDateSelection
                                        }
                                        handleFormSelectChangeItems={
                                            handleFormSelectChangeItems
                                        }
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        configValues={configValues}
                                        id={id}
                                        deleteEnrolement={deleteEnrolement}
                                        addEnrolement={addEnrolement}
                                    />

                                    {!hasInvoice && (
                                        <AddEditPaymentDetails
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            values={values}
                                            configValues={configValues}
                                            setFieldValue={setFieldValue}
                                            editEnrolment={editEnrolment}
                                            errors={errors}
                                        /> 
                                    )}
                                    <hr />
                                    <Row>
                                        <Col md={5} className="d-flex">
                                            {!isSubmitting &&(
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                type="submit"
                                                className="col-md-5 "
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                            )}

                                            {!isSubmitting && hasInvoice && (editEnrolment?.status_key?.toUpperCase() === "PENDING-CONFIRMATION") && (
                                                <Button
                                                    variant="primary text-nowrap ms-2"
                                                    size="lg"
                                                    type="button"
                                                    className="col-md-6 "
                                                    disabled={isSubmitting}
                                                    onClick={() => confirmEnrolment(values)}
                                                >
                                                    Save and confirm enrolled
                                                </Button>
                                            )}

                                            {isSubmitting && (
                                            <div class="col-md-5 d-flex justify-content-center align-items-center">
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            )}

                                            <Button
                                                onClick={() =>
                                                    navigate(
                                                        "/tms/enrolments/enrolled"
                                                    )
                                                }
                                                variant="danger"
                                                size="lg"
                                                type="submit"
                                                className="col-md-5 ms-2 me-2"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Card.Body>
            </Card>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
export default FormRow;
